import { Dialog, Listbox, Menu, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { RiArrowDropDownLine } from "react-icons/ri";
import useSWR from "swr";
import { boardColumns, boardStatus } from "../../constants/board";
import Avatar from "../Avatar";
import { formatTimestamp, timeSince } from "../../utils/common";
import axios from "axios";
import { CheckmarkIcon, toast } from "react-hot-toast";
import Loading from "../Loading";
import Skeleton from "../Skeleton";
import Button from "../Button";
import { HiOutlineXMark } from "react-icons/hi2";
import { BsCheck2 } from "react-icons/bs";
import { HiDotsHorizontal, } from "react-icons/hi";
import useAuth from "../../hooks/useAuth";
import { ImBin, ImPencil2 } from "react-icons/im";
import { AiOutlineMinusCircle } from "react-icons/ai";
import CopyToClipboard from "react-copy-to-clipboard";
import { FiCheckCircle, FiX } from "react-icons/fi";
import { FaRegCopy } from "react-icons/fa";
import { PiCopySimpleLight } from "react-icons/pi";
import { FaHardDrive } from "react-icons/fa6";
import copy from "copy-to-clipboard";
import EnrichData from "./EnrichData";


const LeadPopup = ({
  isOpen,
  close,
  email,
  source: leadSource,
  leadsMutate,
  phone,
  handleSave,

}) => {
  const {
    data: leadData,
    error: leadError,
    mutate: leadMutate,
  } = useSWR(email && `/crm/${email}?source=${leadSource}`);

  const { user } = useAuth();
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape" && isOpen) {
        close();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen, close]);

  const [selectedStatus, setSelectedStatus] = useState("");
  const [loading, setLoading] = useState({
    status: false,
    notes: false,
    assignee: false,
    update: false,
    deleteNote: new Set(),
    enrichData: false,
  });
  const [copiedField, setCopiedField] = useState(null);

  const handleCopy = (field, e) => {
    e.stopPropagation();
    let textToCopy = '';
    if (field === "email") {
      textToCopy = email;
    } else if (field === "phone") {
      textToCopy = phone;
    }
    copy(textToCopy);
    setCopiedField(field);
    setTimeout(() => setCopiedField(null), 1000);
  };

  const [selectedToEdit, setSelectedToEdit] = useState(null);
  const [openNoteInput, setOpen] = useState(false);
  const [note, setNote] = useState("");
  const [isEditing, setIsEditing] = useState({
    name: false,
    email: false,
    phone: false
  });
  const [editedData, setEditedData] = useState({
    fullName: "",
    email: "",
    phone: ""
  });

  const handleInputChange = (field, value) => {
    setEditedData((prev) => ({ ...prev, [field]: value }));
  };
  const memoizedData = useMemo(() => {
    setSelectedStatus(leadData?.[email]?.status);
    return { ...leadData?.[email], notes: leadData?.notes } ?? null;
  }, [email, leadData]);
  const {
    fullName,
    avatarUrl,
    created_at,
    source,
    assignedTo,
    status,
    notes,
    hippoTrust,
    subscription,
  } = memoizedData || {};
  useEffect(() => {
    setEditedData({
      fullName: fullName || "",
      email: email || "",
      phone: phone || ""
    });
  }, [fullName, email, phone]);
  const handleDataEnrich = () => {
    setLoading((prev) => ({ ...prev, enrichData: true }));
    axios
      .post(
        `/crm/verify`,
        { email: email, source: leadSource },
        {
          headers: { "x-api-key": process.env.REACT_APP_X_API_KEY_CRM || "" },
        }
      )
      .then(async (res) => {
        const { first_name, last_name } = res.data?.person?.data || {};
        setEditedData((prevData) => ({
          ...prevData,
          fullName:
            (first_name === null && last_name === null) ? prevData.fullName : `${first_name || ''} ${last_name || ''}`.trim(),
        }));
        await leadMutate();
        toast.success("Data enrich successful!");
      })
      .catch((err) => {
        const error =
          err?.response?.data?.message || err?.response?.data?.error;
        if (error) toast.error(error);
        else toast.error("Something went wrong!");
      })
      .finally(() => {
        setLoading((prev) => ({ ...prev, enrichData: false }));
      });
  };

  const updateStatus = (updatedStatus = "") => {
    const body = { email, source: leadSource };

    if (updatedStatus) {
      body["status"] = updatedStatus;
      setLoading((prev) => ({ ...prev, status: true }));
    }
    axios
      .put("/crm", body, {
        headers: { "x-api-key": process.env.REACT_APP_X_API_KEY_CRM || "" },
      })
      .then(async () => {
        leadsMutate();
        await leadMutate();
      })
      .catch((err) => {
        const error =
          err?.response?.data?.message || err?.response?.data?.error;
        setSelectedStatus(status);
        if (error) toast.error(error);
        else toast.error("Something went wrong!");
      })
      .finally(() => {
        setLoading((prev) => ({ ...prev, status: false }));
      });
  };


  const handleAssignee = (remove = false) => {
    setLoading((prev) => ({ ...prev, assignee: true }));
    axios
      .put(
        "/crm",
        {
          email,
          assignedTo: remove ? "" : user?.email ?? "",
          source: leadSource,
        },
        {
          headers: { "x-api-key": process.env.REACT_APP_X_API_KEY_CRM || "" },
        }
      )
      .then(async () => await leadMutate())
      .catch((err) => {
        const error =
          err?.response?.data?.message || err?.response?.data?.error;
        if (error) toast.error(error);
        else toast.error("Something went wrong!");
      })
      .finally(() => {
        setLoading((prev) => ({ ...prev, assignee: false }));
      });
  };

  const handleNotes = async (isNew) => {
    const body = { email, type: "note", note, source: leadSource };

    setLoading((prev) => ({ ...prev, notes: true }));
    let promise;
    if (isNew) {
      body["created_by"] = user.email ?? "";
      promise = axios.post("/crm", body, {
        headers: { "x-api-key": process.env.REACT_APP_X_API_KEY_CRM || "" },
      });
    } else {
      body["noteId"] = selectedToEdit?.id;
      promise = axios.put("/crm", body, {
        headers: { "x-api-key": process.env.REACT_APP_X_API_KEY_CRM || "" },
      });
    }

    promise
      .then(async () => {
        await leadMutate();
        setOpen(false);
        selectedToEdit && setSelectedToEdit(null);
      })
      .catch((err) => {
        const error =
          err?.response?.data?.message || err?.response?.data?.error;
        setSelectedStatus(status);
        if (error) toast.error(error);
        else toast.error("Something went wrong!");
      })
      .finally(() => {
        setLoading((prev) => ({ ...prev, notes: false }));
      });
  };

  const handleDeleteNote = (noteId) => {
    setLoading((prev) => ({
      ...prev,
      deleteNote: new Set([...prev.deleteNote, noteId]),
    }));
    axios
      .delete("/crm", {
        data: { email, noteId, type: "note", source: leadSource },
        headers: { "x-api-key": process.env.REACT_APP_X_API_KEY_CRM || "" },
      })
      .then(async () => {
        await leadMutate();
      })
      .catch((err) => {
        const error =
          err?.response?.data?.message || err?.response?.data?.error;
        setSelectedStatus(status);
        if (error) toast.error(error);
        else toast.error("Something went wrong!");
      })
      .finally(() => {
        setLoading((prev) => ({
          ...prev,
          deleteNote: new Set([...prev.deleteNote].filter((i) => i !== noteId)),
        }));
      });
  };
  const handleSaveWrapper = async (field) => { // Create a wrapper function
    handleSave(field, editedData); // Call the handleSave prop with editedData
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={() => { }}
      >
        <div className="relative min-h-screen  text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-[90%]  py-6 text-left align-middle transition-all transform bg-background-2 shadow-[0_1px_10px_3px_rgba(111,84,198,0.5)] rounded-lg">
              {!leadData && !leadError ? (
                <Skeleton type={"popup"} />
              ) : (
                <div className="flex item-center  justify-between px-10 gap-4  divide-x-0 md:divide-x-[2px] divide-border">
                  <div className="  max-h-[580px]">
                    <Dialog.Title
                      as="h3"
                      className="text-2xl font-semibold leading-7 text-heading-1 flex items-center space-x-1"
                    >
                      <Avatar
                        src={avatarUrl ?? ""}
                        alt="Profile"
                        className="w-7 h-7 border border-border"
                      />
                      <span className="capitalize">{fullName ?? "N/A"}</span>
                    </Dialog.Title>

                    {/* Notes */}
                    <div className="mt-4 ">
                      <h6 className="text-heading-1 text-sm md:text-base font-medium leading-normal">
                        Add Notes
                      </h6>
                      {
                        <Transition
                          as={Fragment}
                          enter="transform transition duration-[400ms]"
                          enterFrom="opacity-0 rotate-[-120deg] scale-50"
                          enterTo="opacity-100 rotate-0 scale-100"
                          leave="transform duration-200 transition ease-in-out"
                          leaveFrom="opacity-100 rotate-0 scale-100 "
                          leaveTo="opacity-0 scale-95 "
                        >
                          <form
                            className="relative"
                            onSubmit={(e) => {
                              e.preventDefault();
                              if (selectedToEdit) handleNotes(false);
                              else handleNotes(true);
                            }}
                          >
                            <textarea
                              required
                              name="note"
                              type="text"
                              placeholder="add note..."
                              value={note}
                              rows={4}
                              className="appearance-none bg-transparent shadow-sm block md:w-[23rem] w-[16rem] mt-1 px-5 py-2 border border-border rounded-t-md placeholder-gray-400 sm:text-sm focus:z-10 focus:outline-none "
                              onChange={(e) => setNote(e.target.value)}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter' && !e.shiftKey) {
                                  e.preventDefault();
                                  if (selectedToEdit) handleNotes(false);
                                  else handleNotes(true);
                                }
                              }}
                            />
                            <div className="md:w-full w-[16rem] bottom-0 p-2 bg-transparent border border-border rounded-b-md  flex justify-end space-x-0.5">
                              <Button
                                disabled={
                                  loading.notes || (!notes?.length && !note)
                                }
                                loading={loading.notes}
                                type="submit"
                                kind="Outline"
                                className="mx-1"
                              >
                                <BsCheck2 />
                              </Button>
                              <Button
                                disabled={loading.notes}
                                onClick={() => {
                                  setNote("");
                                  setSelectedToEdit(null);
                                }}
                                className=""
                                kind="Outline"
                              >
                                <HiOutlineXMark />
                              </Button>
                            </div>
                          </form>
                        </Transition>
                      }

                      {/* List of notes */}
                      <div className="md:mt-4 mt-0  h-auto max-h-32 overflow-auto w-[16rem] md:w-[23rem] text-logo2 no-scrollbar ">
                        {notes?.length ? (
                          <ul className="space-y-1 text-logo2 mt-2 list-none">
                            {notes.map((note, index) => {
                              const {
                                id,
                                note: noteText,
                                created_at,
                                updated_at,
                                created_by,
                              } = note || {};
                              return (
                                <div
                                  key={`${index}__${id}`}
                                  className={`flex justify-between text-wrap border-b border-border  items-start group ${loading.deleteNote?.has(id)
                                    ? "animate-pulse"
                                    : ""
                                    }`}
                                >
                                  <li className="list-item list-inside text-wrap text-secondary-txt text-sm md:text-base">
                                    {noteText}{" "}
                                    {updated_at && (
                                      <small className="text-[10px]">
                                        updated {timeSince(updated_at)}
                                      </small>
                                    )}
                                    <small className="block text-wrap text-[10px]">
                                      Created by <b>{created_by ?? "N/A"}</b>{" "}
                                      {timeSince(created_at)}
                                    </small>
                                  </li>

                                  {loading.deleteNote?.has(id) ? (
                                    <Loading width="4" height="4" />
                                  ) : (
                                    <Menu
                                      as="div"
                                      className={`relative  group-hover:block`}
                                    >
                                      <Menu.Button className="inline-flex w-full  justify-center text-white rounded  px-1 font-medium focus:outline-none focus:ring-1 focus:ring-offset-1 ring-primary">
                                        <HiDotsHorizontal
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      </Menu.Button>
                                      <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                      >
                                        <Menu.Items className="absolute z-50 top-5 right-0 w-32 origin-top-right divide-y divide-border-border rounded-md bg-background-4 shadow-lg focus:outline-none border border-border-1">
                                          <div className="px-1 py-1  ">
                                            <Menu.Item>
                                              {({ active }) => (
                                                <button
                                                  onClick={() => {
                                                    setNote(noteText);
                                                    setSelectedToEdit(note);
                                                  }}
                                                  className={`${active
                                                    ? "bg-background-3 text-heading-1"
                                                    : "text-label"
                                                    } group flex gap-1 w-full items-center rounded-md px-2 py-2 text-xs`}
                                                >
                                                  <ImPencil2 />
                                                  <span>Edit</span>
                                                </button>
                                              )}
                                            </Menu.Item>
                                            <Menu.Item>
                                              {({ active }) => (
                                                <button
                                                  disabled={loading.deleteNote?.has(
                                                    id
                                                  )}
                                                  onClick={() =>
                                                    handleDeleteNote(note?.id)
                                                  }
                                                  className={`${active
                                                    ? "bg-background-3 text-heading-1"
                                                    : "text-label"
                                                    } group flex gap-1 w-full items-center rounded-md px-2 py-2 text-xs`}
                                                >
                                                  <ImBin className="text-red-light-txt" />
                                                  <span className="text-red-light-txt">Delete</span>
                                                </button>
                                              )}
                                            </Menu.Item>
                                          </div>
                                        </Menu.Items>
                                      </Transition>
                                    </Menu>
                                  )}
                                </div>
                              );
                            })}
                          </ul>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                  </div>
                  <div className="pl-0 md:pl-4 mt-10 md:mt-0">
                    <div className="md:ml-8 ml-0 ">

                      <h6 className="text-heading-1 hidden md:block text-base font-medium leading-normal">
                        Details
                      </h6>
                      <div className='flex justify-between items-center'>
                        <h6 className="text-heading-1 md:hidden block  text-sm font-medium leading-normal">
                          Details
                        </h6>
                        <Listbox
                          value={selectedStatus}
                          onChange={(e) => {
                            updateStatus(e);
                            setSelectedStatus(e);
                          }}
                          disabled={loading.status}
                        >
                          <div className=" relative md:hidden block">
                            <Listbox.Button className="flex w-32 justify-between relative items-center rounded-lg  px-2 py-1 focus:outline-none  border border-border ring-primary disabled:cursor-not-allowed">
                              <span className="text-heading-1 text-md p-1 font-medium">
                                {boardStatus[selectedStatus]}
                              </span>
                              {loading.status ? (
                                <Loading width="4" height="4" />
                              ) : (
                                <RiArrowDropDownLine className="w-6 h-6" />
                              )}
                            </Listbox.Button>
                            <Transition
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute z-10 w-32 mt-1 right-0 divide-y divide-border-border rounded-md bg-background-4 shadow-lg focus:outline-none border border-border">
                                <div className="px-1 py-1">

                                  {boardColumns.map((col) => {
                                    if (status !== col.id) {
                                      return (
                                        <Listbox.Option
                                          key={col.id}
                                          className={() =>
                                            `group flex gap-1 w-full items-center text-sm rounded-md px-2 py-2 cursor-pointer hover:bg-primary hover:text-white `
                                          }
                                          value={col.id}
                                        >
                                          {() => (
                                            <>
                                              <span
                                                className={`block truncate   hover:bg-primary hover:text-white`}
                                              >
                                                {col.title}
                                              </span>
                                            </>
                                          )}

                                        </Listbox.Option>
                                      );
                                    } else return <Fragment key={col.id}></Fragment>;
                                  })}
                                  {assignedTo ? (
                                    <p className="flex items-center text-sm ">
                                      <span>{assignedTo ? `${assignedTo.slice(0, 12)}${assignedTo.length > 8 ? "..." : ""}` : "N/A"}</span>
                                      <button onClick={() => handleAssignee(true)}>
                                        {loading.assignee ? <Loading width="4" height="4" /> : <AiOutlineMinusCircle className="text-error-red" />}
                                      </button>
                                    </p>
                                  ) : (
                                    <button

                                      disabled={loading.assignee}
                                      onClick={() => handleAssignee(false)}
                                      className="text-xs`group flex gap-1 w-full text-sm items-center rounded-md px-2 py-2 cursor-pointer hover:bg-primary hover:text-white  "
                                    >
                                      {loading.assignee && <Loading width="4" height="4" />}
                                      Assign to
                                    </button>
                                  )}
                                </div>
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </Listbox>

                      </div>

                      <div className="md:mt-6 mt-1 flex flex-col space-y-1 md:space-y-5 text-logo2">
                        <h6 className=" text-sm font-medium leading-normal flex items-center">
                          <span className="min-w-[120px] text-secondary-txt">Name</span>

                          {isEditing.name ? (
                            <div>
                              <input
                                type="text"
                                value={editedData.fullName}
                                onChange={(e) => handleInputChange("fullName", e.target.value)}
                                className="border border-gray-300 rounded px-2 py-1"
                              />
                              <button
                                onClick={() => setIsEditing({ ...isEditing, name: false })}
                                className="ml-2" // Add some margin for spacing
                              >
                                <HiOutlineXMark className="text-gray-500" />
                              </button>
                            </div>
                          ) : (
                            <div>
                              <p
                                className="capitalize text-secondary-txt-1"
                                onClick={() => setIsEditing({ ...isEditing, name: true })}
                              >

                                {editedData.fullName
                                  ? editedData.fullName
                                  : "N/A"
                                }

                              </p>
                              <hr className="border-border border w-72" />
                            </div>
                          )}

                        </h6>

                        <h6 className=" text-sm font-medium leading-normal flex items-center">
                          <span className="min-w-[120px] text-secondary-txt">Email</span>
                          {isEditing.email ? (
                            <div className="flex"> {/* Wrap input and icon in a flex container */}
                              <input
                                type="text"
                                value={editedData.email}
                                onChange={(e) => handleInputChange("email", e.target.value)}
                                className="border border-gray-300 rounded px-2 py-1"
                              />
                              <button
                                onClick={() => setIsEditing({ ...isEditing, email: false })}
                                className="ml-2" // Add some margin for spacing
                              >
                                <HiOutlineXMark className="text-gray-500" />
                              </button>
                            </div>
                          ) : (
                            <div>
                              <div className="flex items-center justify-between">
                                <p
                                  className="lowercase  text-secondary-txt-1"
                                  onClick={() => setIsEditing({ ...isEditing, email: true })}
                                >
                                  {editedData.email || "N/A"}
                                </p>
                                <div>
                                  <div onClick={(e) => handleCopy("email", e)} className="cursor-pointer">
                                    {copiedField === "email" ? <FiCheckCircle className="text-green-500" /> : <PiCopySimpleLight className="text-secondary-txt" />}
                                  </div>
                                </div>

                              </div>
                              <hr className="border-border border w-72" />
                            </div>
                          )}
                        </h6>
                        <h6 className=" text-sm font-medium leading-normal flex items-center">
                          <span className="min-w-[120px] text-secondary-txt">phone</span>
                          {isEditing.phone ? (
                            <div className="flex"> {/* Wrap input and icon in a flex container */}
                              <input
                                type="text"
                                value={editedData.phone}
                                onChange={(e) => handleInputChange("phone", e.target.value)}
                                className="border border-gray-300 rounded px-2 py-1"
                              />
                              <button
                                onClick={() => setIsEditing({ ...isEditing, phone: false })}
                                className="ml-2" // Add some margin for spacing
                              >
                                <HiOutlineXMark className="text-gray-500" />
                              </button>
                            </div>
                          ) : (
                            <div>
                              <div className="flex items-center justify-between">
                                <p
                                  className="capitalize text-secondary-txt-1"
                                  onClick={() => setIsEditing({ ...isEditing, phone: true })}
                                >
                                  {editedData.phone || "N/A"}
                                </p>
                                <div>
                                  <div onClick={(e) => handleCopy("phone", e)} className="cursor-pointer">
                                    {copiedField === "phone" ? <FiCheckCircle className="text-green-500" /> : <PiCopySimpleLight className="text-secondary-txt" />}
                                  </div>
                                </div>

                              </div>
                              <hr className="border-border border w-72" />
                            </div>
                          )}
                        </h6>
                        <h6 className=" text-sm font-medium leading-normal flex items-center">
                          <span className="min-w-[120px] text-secondary-txt">Subscription</span>
                          <div className="flex flex-col">
                            <p className="capitalize text-secondary-txt-1">
                              {subscription === "pro"
                                ? "Professional"
                                : subscription || "N/A"}
                            </p>
                            <hr className="border-border border w-72" />
                          </div>
                        </h6>
                        <h6 className="text-sm font-medium leading-normal flex items-center">
                          <span className="min-w-[120px] text-secondary-txt">Status</span>
                          <Listbox
                            value={selectedStatus}
                            onChange={(e) => {
                              updateStatus(e);
                              setSelectedStatus(e);
                            }}
                            disabled={loading.status}
                          >
                            <div className=" relative hidden md:block">
                              <Listbox.Button className="flex w-32 justify-between relative text-secondary-txt-1 items-center rounded-lg   focus:outline-none   ring-primary disabled:cursor-not-allowed">
                                <span className=" text-md  font-medium">
                                  {boardStatus[selectedStatus]}
                                </span>

                                {loading.status ? (
                                  <Loading width="4" height="4" />
                                ) : (
                                  <RiArrowDropDownLine className="w-6 h-6 mr-10" />
                                )}
                              </Listbox.Button>
                              <hr className="border-border border w-72" />
                              <Transition
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Listbox.Options className="absolute z-10 w-28 mt-1 left-0 divide-y divide-border-border rounded-md bg-background-4 shadow-lg focus:outline-none border border-border">
                                  <div className="px-1 py-1">

                                    {boardColumns.map((col) => {
                                      if (status !== col.id) {
                                        return (
                                          <Listbox.Option
                                            key={col.id}
                                            className={() =>
                                              `group flex gap-1 w-full items-center text-sm rounded-md px-2 py-2 cursor-pointer hover:bg-primary hover:text-white `
                                            }
                                            value={col.id}
                                          >
                                            {() => (
                                              <>
                                                <span
                                                  className={`block truncate   hover:bg-primary hover:text-white`}
                                                >
                                                  {col.title}
                                                </span>
                                              </>
                                            )}

                                          </Listbox.Option>
                                        );
                                      } else return <Fragment key={col.id}></Fragment>;
                                    })}

                                  </div>
                                </Listbox.Options>
                              </Transition>
                            </div>
                          </Listbox>
                        </h6>
                        <h6 className=" text-sm font-medium text-secondary-txt-1 leading-normal flex items-center">
                          <span className="min-w-[120px] text-secondary-txt" >Trust Level</span>
                          <div className="flex flex-col">
                            {hippoTrust ? (
                              <p className="">
                                {hippoTrust?.level} (Score {hippoTrust?.score})
                              </p>
                            ) : (
                              "N/A"
                            )}
                            <hr className="border-border border w-72" />
                          </div>

                        </h6>
                        <h6 className=" text-sm font-medium leading-normal flex items-center">
                          <span className="min-w-[120px] text-secondary-txt">Created on</span>
                          <div className="flex flex-col">
                            <p className="text-secondary-txt-1">
                              {created_at ? formatTimestamp(created_at) : "N/A"}
                            </p>
                            <hr className="border-border border w-72" />
                          </div>
                        </h6>
                        <h6 className="text-sm font-medium leading-normal flex items-center">
                          <span className="min-w-[120px] text-secondary-txt">Source</span>
                          <div className="flex flex-col">
                            <p className="capitalize text-secondary-txt-1">{source ?? "N/A"}</p>
                            <hr className="border-border border w-72" />
                          </div>
                        </h6>
                        <div className="flex justify-between  items-center">
                          <Button
                            disabled={!isEditing.name && !isEditing.email && !isEditing.phone}
                            onClick={() => {
                              if (isEditing.name) {
                                handleSaveWrapper("fullName"); // Call the wrapper function
                                toast.success("Name updated successfully!");
                              }
                              if (isEditing.email) {
                                handleSaveWrapper("email"); // Call the wrapper function
                                toast.success("Email updated successfully!");
                              }
                              if (isEditing.phone) {
                                handleSaveWrapper("phone"); // Call the wrapper function
                                toast.success("Phone updated successfully!");
                              }
                            }}
                          >
                            {loading.update ? (
                              <Loading width="4" height="4" />
                            ) : (
                              "Update"
                            )}
                          </Button>

                          <div className="mr-10 flex gap-2">

                            {assignedTo ? (
                              <p className="flex items-center text-sm ">
                                <span>{assignedTo ? `${assignedTo.slice(0, 12)}${assignedTo.length > 8 ? "..." : ""}` : "N/A"}</span>
                                <button onClick={() => handleAssignee(true)}>
                                  {loading.assignee ? <Loading width="4" height="4" /> : <AiOutlineMinusCircle className="text-error-red" />}
                                </button>
                              </p>
                            ) : (
                              <button
                                disabled={loading.assignee}
                                onClick={() => handleAssignee(false)}
                                className="text-xs`group flex gap-1 w-full text-sm border border-border items-center rounded-md px-2 py-2 cursor-pointer hover:bg-primary hover:text-white  "
                              >
                                {loading.assignee && <Loading width="4" height="4" />}
                                Assign to
                              </button>
                            )}
                            <div className="flex justify-between whitespace-nowrap  items-center gap-2 cursor-pointer text-sm text-primary">
                              <FaHardDrive />
                              <p onClick={handleDataEnrich} > {loading.enrichData ? ( // Conditionally render loading state or text
                                <Loading width="4" height="4" />
                              ) : (
                                "Enrich Data"
                              )}</p>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>

                  </div>



                  <div className=" w-[30rem]">
                    <h6 className="text-heading-1  p-3 text-sm md:text-base font-medium leading-normal">
                      Enriched Data
                    </h6>
                    <EnrichData email={email} leadData={memoizedData} />

                  </div>



                  <div className="border-none">
                    <button onClick={close}>
                      <FiX className="h-5 w-5 text-heading-1 rounded-full  absolute top-7 right-7" />
                    </button>

                  </div>

                </div>

              )}

            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default LeadPopup;
