import React, { Fragment, useState } from "react";
import useAuth from "../hooks/useAuth";
import { Menu, Transition } from "@headlessui/react";
import { FiLogOut } from "react-icons/fi";
import { FaAngleDown } from "react-icons/fa6";
import { useTheme } from "next-themes";
import { HiOutlineMoon, HiSun } from "react-icons/hi";
import Avatar from "./Avatar";
import c from "classnames";

import Notifications from "./Notification";
import { IoMenuOutline } from "react-icons/io5";
import { useSourceFilter } from "../context/SourceFilterContext";
const Header = ({ className, setIsOpened }) => {
  const { user, logout } = useAuth();
  const { theme, setTheme } = useTheme();
  const { selectedSource, handleSourceChange } = useSourceFilter();
  const [selectedButton, setSelectedButton] = useState("Rabbito");
  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown(prev => !prev);
  };

  const handleButtonClick = name => {
    setSelectedButton(name);
    handleSourceChange(name.toLowerCase());
    setShowDropdown(false);
  };
  const sources = [
    { id: 'rabbito', title: 'Rabbito' },
    { id: 'objex', title: 'Objex' },
    { id: 'hbhm', title: 'HBHM' },
    { id: 'mazrnow', title: 'Mazrnow' },
    { id: 'cricketottawa', title: 'Cricket Ottawa' },
    { id: 'objextv', title: 'Objex TV' }
  ];


  return (
    <div
      className={c(
        "md:px-3 md:py-[9px] px-1 flex md:justify-end justify-start items-center",
        className
      )}
    >
      <div className="flex border border-border rounded justify-start items-center lg:hidden">
        <button
          onClick={() => setIsOpened(true)}
          className="  "
        >
          <IoMenuOutline className="h-8 w-8 " />
        </button>
      </div>
      <div className="text-3xl md:hidden w-14 h-10 flex items-center justify-start m-1 font-semibold">
        <img
          src="/images/small-logo.png"
          className="max-w-full max-h-full object-contain"
          alt="Rabbito Logo"
        />
      </div>


      <div className="flex items-center gap-3 ml-12 justify-end">
        <div >
          <Menu as="div" className="">
            <Menu.Button
              className="inline-flex w-full justify-between mr-3 p-1 py-1.5 md:px-4 md:py-2.5 bg-transparent  border border-border rounded-full opacity-90 hover:opacity-100 font-sm text-heading-2 focus:outline-none"
              onClick={toggleDropdown}
            >
              <p className="flex justify-between md:p-0 p-1 text-[10px] md:text-[14px] items-center">
                {selectedButton}
                <FaAngleDown className="ml-2" />
              </p>
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-24 md:right-64 top-10 md:top-16 w-16 md:w-28 origin-top-left divide-y divide-border-border-1 rounded-md bg-background-4 shadow-lg focus:outline-none border border-border-1 z-50">
                <div className="px-1 py-1">
                  {sources.map(source => (
                    <Menu.Item key={source.id}>
                      <button
                        className={` block md:px-4 py-2 px-2 text-[10px] md:text-sm  hover:bg-primary hover:text-white rounded-md w-full text-left`}
                        onClick={() => handleButtonClick(source.title)}
                      >
                        {source.title}
                      </button>
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>

            </Transition>
          </Menu>
        </div>

        <div className="hidden  md:block mx-2">
          <button
            onClick={() => {
              const nextTheme = theme === "light" ? "dark" : "light";
              setTheme(nextTheme);
            }}
            className=" border border-border rounded-full p-2 md:p-2.5 bg-transparent "

          >
            {theme === "light" ? (
              <HiOutlineMoon className="md:w-6 md:h-6 h-4 w-4 text-logo2" />
            ) : (
              <HiSun className=" md:w-6 md:h-6 h-4 w-4 text-logo2" />
            )}
          </button>
        </div>
        <Notifications />

        <Menu as="div">
          <Menu.Button className="inline-flex  md:felx items-center w-full justify-center rounded opacity-90 hover:opacity-100 font-medium focus:outline-none">
            <Avatar
              src={user?.photoURL}
              alt="Profile"
              className="md:w-11 md:h-11 h-9 w-9 mt-1 flex items-center border border-border active:ring-1 ring-primary"

            />
            <p className="text-sm text-heading-1 px-2 font-medium hidden md:block lg:block">
              {user?.displayName}
            </p>
            <FaAngleDown className="mt-2 mr-5" />
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-10 w-40 py-2 md:w-32 origin-top-right divide-y divide-border-border-1 rounded-md bg-background-4 shadow-lg focus:outline-none border border-border-1 z-50">
              <div className="px-1 py-1 ">
                <Menu.Item >
                  <p className="text-sm text-heading-1 py-2 px-2  font-medium block lg:block">
                    {user?.displayName ? user.displayName : user?.email && user.email.length > 13
                      ? `${user.email.slice(0, 13)}...`
                      : user?.email}
                  </p>
                </Menu.Item>
                <hr className="border-border border" />
                <Menu.Item className="md:hidden block">

                  <div
                    onClick={() => {
                      const nextTheme = theme === "light" ? "dark" : "light";
                      setTheme(nextTheme);
                    }}
                    className="py-3 bg-transparent "

                  >
                    <p>
                      {theme === "light" ? (<span className="flex items-center ">
                        <HiOutlineMoon className="md:w-6 md:h-6 h-4 w-4 mr-2 text-logo2" />
                        <span className="text-sm text-heading-1">
                          Dark Mode
                        </span>
                      </span>

                      ) : (
                        <span className="flex items-center ">
                          <HiSun className=" md:w-6 md:h-6 h-4 w-4 mr-2 text-logo2" />
                          <span className="text-sm text-heading-1">
                            Light Mode
                          </span>
                        </span>

                      )}
                    </p>


                  </div>

                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={() => logout()}
                      className={`${active ? "bg-background-inactive text-red-light-txt " : "text-red-light-txt"
                        } group flex gap-1 w-full items-center rounded-md px-2 py-2 text-sm`}
                    >
                      <FiLogOut />
                      <span>Logout</span>
                    </button>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  );
};

export default Header;
